var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import formatISO9075 from 'date-fns/formatISO9075';
var CAMPAIGN_KEY = 'ws_campaign_name';
var getLocalStore = function (key) { return localStorage.getItem(key); };
var setLocalStore = function (key, value) { return localStorage.setItem(key, JSON.stringify(value)); };
var getCurrentTime = function () { return formatISO9075(new Date(), { representation: "complete" }); };
export var getCampaigns = function () {
    var _a;
    var campaigns = getLocalStore(CAMPAIGN_KEY);
    if (!campaigns)
        return {};
    try {
        return JSON.parse(campaigns);
    }
    catch (_b) {
        return _a = {}, _a[campaigns.toLowerCase()] = getCurrentTime(), _a;
    }
};
var getQueryParams = function (url, filter) {
    return Array.from(url.searchParams.entries()).reduce(function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        if (!filter(key))
            return acc;
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
/**
 *
 * @searchParams format `?campaign_name=` or a valid URL
 *
 */
var extractCampaignsFromURL = function (searchParams) {
    var url = new URL(searchParams, window.location.origin);
    var keyFilter = function (key) { return ["cn", "campaign", "campaign_name"].includes(key); };
    var _a = getQueryParams(url, keyFilter), cn = _a.cn, campaign = _a.campaign, campaign_name = _a.campaign_name;
    return [].concat(cn, campaign, campaign_name).filter(Boolean).reduce(function (all, x) {
        var name = x.toLowerCase();
        return all.concat((!name.endsWith("/") ? name : name.substring(0, name.length - 1)).split(","));
    }, []).map(function (x) { return x.trim(); });
};
var extractAllCampaigns = function (searchParams, campaignName) {
    if (searchParams === void 0) { searchParams = ''; }
    if (campaignName === void 0) { campaignName = ''; }
    var hostCampaignName = extractCampaignsFromURL(window.location.search);
    var appCampaignName = extractCampaignsFromURL(window.location.hash.substr(1));
    var injectedCampaignNames = extractCampaignsFromURL(searchParams);
    var now = getCurrentTime(), storedCampaign = getCampaigns();
    return hostCampaignName
        .concat(appCampaignName)
        .concat(injectedCampaignNames)
        .concat(campaignName.split(","))
        .reduce(function (store, key) {
        key = key.trim();
        if (key && !store[key]) {
            store[key] = now;
        }
        return store;
    }, storedCampaign);
};
export var persistCampaigns = function (arg) {
    if (arg === void 0) { arg = {}; }
    var store = extractAllCampaigns(arg.searchParams, arg.campaignName);
    if (Object.keys(store).length) {
        setLocalStore(CAMPAIGN_KEY, store);
        return store;
    }
    return null;
};
